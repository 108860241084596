import {OAuth2AuthenticationDto} from '@next-solutions/next-solutions-base';

export const environment = {
  production: true,
  BASE_AUTHORIZATION_URL: 'https://sso-api.cardoctor.com.vn',
  CLIENT_ID: 'CarDoctor',
  CLIENT_SECRET: '2qazXSW@3edcVFR$5tgbNHY^7ujm<KI*9ol.?:P):))',
  PAGE_SIZE: 10,
  PAGE_SIZE_OPTIONS: [5, 10, 20, 30, 50, 100],
  DEFAULT_LANGUAGE: 'vi',
  DEFAULT_THEME: 'default',

  API_DATE_FORMAT: 'yyyy-MM-dd HH:mm:ss.SSS\'Z\'',
  DIS_DATE_FORMAT: 'dd/MM/yyyy',

  me: {} as OAuth2AuthenticationDto,
  LANGS: [
    {id: 1, code: 'vi', name: 'Việt Nam', logo: 'assets/Flags/vi.ico'},
    {id: 2, code: 'en', name: 'English', logo: 'assets/Flags/en.ico'},
    {id: 3, code: 'my', name: 'Myamar', logo: 'assets/Flags/my.ico'}],

  DIALOG_LOGO: '',
  LOADING_IMAGE: '',
  IS_EMBEDDED: false,
  IS_LABEL_OUTSIDE: true,
  IS_INDEPENDENCIES_SSO: true,
  INTEGER_MAX_VALUE: '2147483647'
};
