import {Component, Injector, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {HttpParams} from '@angular/common/http';
import {AddEditAutoPermissionComponent} from '../add-edit-auto-permission/add-edit-auto-permission.component';
import {MatDialog} from '@angular/material/dialog';
import {
  AlignEnum,
  ApiService,
  AuthoritiesService,
  BaseSearchLayout,
  ButtonFields,
  ColumnFields,
  FormStateService,
  IconTypeEnum,
  Page,
  RoleModel,
  SelectModel,
  UtilsService
} from '@next-solutions/next-solutions-base';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ConfigApplyUserRoleModel} from '../../../_models/configApplyUserRole.model';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-list-auto-permission',
  templateUrl: './list-auto-permission.component.html',
  styleUrls: ['./list-auto-permission.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListAutoPermissionComponent extends BaseSearchLayout implements OnInit {

  moduleName = 'auto-permission';
  columns: ColumnFields[];
  buttons: ButtonFields[];

  clientOptions: SelectModel[] = [];
  roleOptions: SelectModel[] = [];
  options: RoleModel[] = [];

  constructor(protected router: Router, protected apiService: ApiService, protected utilsService: UtilsService,
              protected formStateService: FormStateService, protected translateService: TranslateService,
              protected injector: Injector, private dialog: MatDialog,
              protected activatedRoute: ActivatedRoute,
              protected authoritiesService: AuthoritiesService,
              private fb: FormBuilder) {
    super(router, apiService, utilsService, formStateService, translateService, injector, activatedRoute, authoritiesService, fb.group({
      systemRefUserType: [''],
      clientId: [''],
      roleId: [''],
    }));
    this.columns = [
      {
        columnDef: 'stt',
        header: 'stt',
        title: (e: any) => `${UtilsService.calcPosition(e, this.results, this.paging)}`,
        cell: (e: any) => `${UtilsService.calcPosition(e, this.results, this.paging)}`,
        className: 'mat-column-stt',
        align: AlignEnum.CENTER
      },
      {
        columnDef: 'clientId',
        header: 'clientId',
        title: (e: ConfigApplyUserRoleModel) => `${e.clientId}`,
        cell: (e: ConfigApplyUserRoleModel) => `${e.clientId}`,
        className: 'mat-column-clientId'
      },
      {
        columnDef: 'systemRefUserType',
        header: 'systemRefUserType',
        title: (e: ConfigApplyUserRoleModel) => `${e.systemRefUserType}`,
        cell: (e: ConfigApplyUserRoleModel) => `${e.systemRefUserType}`,
        className: 'mat-column-systemRefUserType'
      },
      {
        columnDef: 'roleId',
        header: 'roleId',
        title: (e: ConfigApplyUserRoleModel) => `${e.role?.roleName}`,
        cell: (e: ConfigApplyUserRoleModel) => `${e.role?.roleName}`,
        className: 'mat-column-roleId'
      },
    ];

    this.buttons = [
      {
        columnDef: 'addEdit',
        icon: 'fa fa-pen',
        iconType: IconTypeEnum.FONT_AWESOME,
        isShowHeader: true,
        display: (e: ConfigApplyUserRoleModel) => e && this.authoritiesService.hasAuthority('patch/sso/configApplyUserRole/{id}'), // Author
        title: 'common.title.edit',
        click: 'addOrEdit',
        className: 'secondary mat-column-edit',
        header: {
          columnDef: 'addEdit',
          icon: 'fa fa-plus',
          iconType: IconTypeEnum.FONT_AWESOME,
          title: 'common.title.add',
          click: 'addOrEdit',
          display: (e: ConfigApplyUserRoleModel) => true && this.authoritiesService.hasAuthority('post/sso/configApplyUserRole'),
        }
      },
      {
        columnDef: 'delete',
        icon: 'fa fa-trash-alt',
        iconType: IconTypeEnum.FONT_AWESOME,
        click: 'delete',
        title: 'common.title.clear',
        className: 'danger',
        display: (e: ConfigApplyUserRoleModel) => !!e && this.authoritiesService.hasAuthority('delete/sso/configApplyUserRole/{id}'),
      }
    ];
  }

  async ngOnInit() {
    const params = new HttpParams()
      .set('pageNumber', '1')
      .set('pageSize', environment.INTEGER_MAX_VALUE);
    Promise.all([
      this.apiService.get<Page>( '/role/find', params).toPromise()
    ]).then(([pageClient]) => {
      if (pageClient && pageClient.content?.length >= 0) {
        this.options = pageClient.content;
      }
    });
    this.apiService.get<string[]>('/oauthClient/getClientIds', new HttpParams())
      .subscribe(data => {
        this.clientOptions = data.map((client: any) => new SelectModel(client, client));
      });
    super.ngOnInit();
    this.onSubmit();
  }

  filterRoleIdByClientId(event: any) {
    this.searchForm.get('roleId')?.setValue('');
    this.roleOptions = [];
    this.roleOptions = this.options
      .filter(a => a.clientId === event)
      ?.map(a => new SelectModel(a.id, a.roleName || ''));
  }

  search(): void {
    this.paging.text = this.searchForm.get('systemRefUserType')?.value;
    const params = new HttpParams()
      .set('roleId', this.searchForm.get('roleId')?.value)
      .set('clientId', this.searchForm.get('clientId')?.value)
      .set('systemRefUserType', this.searchForm.get('systemRefUserType')?.value);
    this._fillData('/configApplyUserRole/find', params);
  }

  addOrEdit(configApplyUserRole: ConfigApplyUserRoleModel): void {
      this.dialog.open(AddEditAutoPermissionComponent, {
        disableClose: false,
        panelClass: 'app-add-edit-auto-permission',
        data: {configApplyUserRole, isView: false}
      }).afterClosed().subscribe(x => {
        if (x) {
          this.search();
        }
      });
  }

  delete(configApplyUserRole: ConfigApplyUserRoleModel) {
    const method = this.apiService.delete('/configApplyUserRole/' + configApplyUserRole.id);
    this.utilsService.execute(method, this.onSuccessFunc, 'common.delete.success', 'common.confirm.delete');
  }
}
