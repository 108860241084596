import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {A11yModule} from '@angular/cdk/a11y';
import {BidiModule} from '@angular/cdk/bidi';
import {ObserversModule} from '@angular/cdk/observers';
import {OverlayModule} from '@angular/cdk/overlay';
import {PlatformModule} from '@angular/cdk/platform';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MultilanguagePanigator} from './_helpers/multilanguage.paginator';

import {AppComponent} from './app.component';
import {LoginComponent} from './shared/login/login.component';
import {AuthenticationService} from './_services/authentication.service';
import {ErrorInterceptor} from './_helpers/error.interceptor';
import {NavService} from './_services/nav.service';
import {MenuListItemComponent} from './shared/menu-list-item/menu-list-item.component';
import {TopNavComponent} from './shared/top-nav/top-nav.component';
import {LogoutComponent} from './shared/logout/logout.component';
import {AppRoutingModule} from './app-routing.module';
import {PhoneValidation} from './validation/PhoneValidation';
import {StringValidation} from './validation/StringValidation';
import {NumberValidation} from './validation/NumberValidation';
import {DateValidation} from './validation/DateValidation';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {FilterComponent} from '../filter/filter.component';
import {WINDOW_PROVIDERS} from './_services/window.providers';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {ApiService, LoaderInterceptor, NextSolutionsModules} from '@next-solutions/next-solutions-base';
import {environment} from '../environments/environment';
import {ToastrModule} from 'ngx-toastr';
import {SharedModule} from './modules/shared.module';
import {TranslateLoaderFactoryHelper} from './_helpers/TranslateLoaderFactoryHelper';
import {SsoModule} from "./modules/sso.module";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    exports: [
        // CDK
        A11yModule,
        BidiModule,
        ObserversModule,
        OverlayModule,
        PlatformModule,
        PortalModule,
        ScrollingModule,
        CdkStepperModule,
        CdkTableModule,
    ],
    declarations: [
        AppComponent,
        LoginComponent,
        LogoutComponent,
        MenuListItemComponent,
        TopNavComponent,
        FilterComponent,
    ],
    imports: [
        /*ONLY ONCE TIME*/
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NextSolutionsModules.forRoot({
            data: {
                BASE_URL: environment.BASE_AUTHORIZATION_URL,
                BASE_AUTHORIZATION_URL: environment.BASE_AUTHORIZATION_URL,
                PAGE_SIZE: environment.PAGE_SIZE,
                PAGE_SIZE_OPTIONS: environment.PAGE_SIZE_OPTIONS,
                API_DATE_FORMAT: environment.API_DATE_FORMAT,
                DIS_DATE_FORMAT: environment.DIS_DATE_FORMAT,
                DIALOG_LOGO: environment.DIALOG_LOGO
            }
        }),
        ToastrModule.forRoot(),
        TranslateModule.forRoot({
            defaultLanguage: environment.DEFAULT_LANGUAGE,
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLoaderFactoryHelper.forModule(),
                deps: [HttpClient],
            },
            isolate: false,
        }),
        SharedModule,
        AppRoutingModule,
        SsoModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: MatPaginatorIntl, useClass: MultilanguagePanigator },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        CookieService,
        LoginComponent,
        AuthenticationService,
        ApiService,
        NavService,
        WINDOW_PROVIDERS,
        PhoneValidation,
        StringValidation,
        NumberValidation,
        DateValidation
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
