import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserModel} from '../../../_models/user.model';
import {ApiService, AuthoritiesService, BaseAddEditLayout, Page, SelectModel, UtilsService} from '@next-solutions/next-solutions-base';
import {environment} from '../../../../environments/environment';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {HttpParams} from '@angular/common/http';


@Component({
  selector: 'app-add-user',
  templateUrl: './add-edit-user.component.html',
  styleUrls: ['./add-edit-user.component.scss'],
})
export class AddEditUserComponent extends BaseAddEditLayout implements OnInit {

  moduleName = 'user';
  title = 'menu.sso.user.add';

  options: any[] = [];
  systemRefOptions: SelectModel[] = [];
  systemRefUserTypeOptions: SelectModel[] = [];
  enableOptions: SelectModel[] = [{value: true, displayValue: 'Kích hoạt', rawData: '', disabled: undefined}, {value: false, displayValue: 'Vô hiệu hóa', rawData: '', disabled: undefined}];


  readonly userNamePattern = '[a-zA-Z0-9]+([a-zA-Z0-9 ]+)*';
  userNamePatternErrorMessage = new Map().set('pattern', () => this.translateService.instant(this.moduleName + '.error.username.pattern'));

  constructor(protected activatedRoute: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected location: Location,
              protected translateService: TranslateService,
              protected apiService: ApiService,
              protected utilsService: UtilsService,
              protected authoritiesService: AuthoritiesService,
              public dialogRef: MatDialogRef<AddEditUserComponent>,
              @Inject(MAT_DIALOG_DATA) public user: UserModel) {
    super(activatedRoute, location, translateService, utilsService, authoritiesService);
    this.addEditForm = this.formBuilder.group({
      id: undefined,
      username: [''],
      firstName: [''],
      lastName: [''],
      enabled: [''],
      systemRef: [''],
      systemRefUserId: [''],
      systemRefUserType: [''],
    });
    if (this.user) {
      console.log(this.user);
      this.title = 'menu.sso.user.edit';
      this.addEditForm.setValue(UtilsService.reduceEntityAttributeForFormControl(this.addEditForm, this.user));
      this.addEditForm.patchValue({
        enabled: this.user.enabled,
        systemRef: this.user.systemRef ? this.user.systemRef : ''
      });
    }
    const params = new HttpParams()
      .set('pageNumber', '1')
      .set('pageSize', environment.INTEGER_MAX_VALUE);
    Promise.all([
      this.apiService.get<Page>( '/configApplyUserRole/find', params).toPromise(),
      this.apiService.get<string[]>('/oauthClient/getClientIds', params).toPromise()
    ]).then(([pageClient, clientData]) => {
      if (clientData) {
        this.systemRefOptions = clientData.map((client: any) => new SelectModel(client, client));
      }
      if (pageClient && pageClient.content?.length >= 0) {
        this.options = pageClient.content;
        this.systemRefChange(this.user.systemRef);
        this.addEditForm.patchValue({
          systemRefUserType: this.user.systemRefUserType,
        });
      }
    });
  }

  async ngOnInit() {
    super.ngOnInit();
  }

  systemRefChange(event: any) {
    console.log(event);
    this.addEditForm.get('systemRefUserType')?.setValue('');
    this.systemRefUserTypeOptions = [];
    this.systemRefUserTypeOptions = this.options
      .filter(a => a.clientId === event)
      ?.map(a => new SelectModel(a.systemRefUserType, a.systemRefUserType || ''));
  }

  hasIdValue() {
    return !!this.addEditForm?.controls.id.value;
  }

  onSuccessFunc = (data: any, onSuccessMessage: string | undefined): void => {
    this.utilsService.onSuccessFunc(onSuccessMessage);
    this.dialogRef.close({ value: true });
  }

  get enviroment() {
    return environment;
  }

  onSubmit() {
    // const userType = this.options.filter((e: any) => e.id === this.addEditForm.get('systemRefUserId')?.value)[0].systemRefUserType;
    // this.addEditForm.get('systemRefUserType')?.setValue(userType);
    console.log(this.addEditForm.value);
    const apiCall = this.hasIdValue()
      ? this.apiService.patch('/user/' + this.user.id, this.addEditForm?.value, {}, environment.BASE_AUTHORIZATION_URL)
      : this.apiService.post('/user', this.addEditForm?.value, {}, environment.BASE_AUTHORIZATION_URL);
    const action = this.hasIdValue() ? 'edit' : 'add';
    this.utilsService.execute(apiCall, this.onSuccessFunc,
      'common.' + action + '.success',
      'common.confirm.' + action, ['user.']);
  }
}
