import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {LogoutComponent} from './shared/logout/logout.component';
import {ListUserComponent} from './components/user/list-user/list-user.component';
import {AuthoritiesResolverService} from '@next-solutions/next-solutions-base';


const routes: Routes = [
  {
    path: 'home',
    data: {breadcrumb: 'menu.sso.user.'},
    component: ListUserComponent,
    resolve: {me: AuthoritiesResolverService},
  },
  {path: 'logout', component: LogoutComponent},
  {
    path: 'sso',
    data: {breadcrumb: ''},
    loadChildren: () => import('./modules/sso.module').then(m => m.SsoModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    relativeLinkResolution: 'legacy',
    onSameUrlNavigation: 'reload',
    // preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
