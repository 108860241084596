import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ApiService, AuthoritiesService,
  BaseAddEditLayout,
  Permission,
  SelectModel,
  UtilsService,
} from '@next-solutions/next-solutions-base';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-add-edit-permission',
  templateUrl: './add-edit-permission.component.html',
  styleUrls: ['./add-edit-permission.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddEditPermissionComponent extends BaseAddEditLayout implements OnInit {


  options: SelectModel[] = [];
  moduleName = 'permission';
  title = '';

  constructor(protected activatedRoute: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected location: Location,
              protected translateService: TranslateService,
              protected apiService: ApiService,
              protected utilsService: UtilsService,
              protected authoritiesService: AuthoritiesService,
              public dialogRef: MatDialogRef<AddEditPermissionComponent>,
              @Inject(MAT_DIALOG_DATA) public permission: Permission) {
    super(activatedRoute, location, translateService, utilsService, authoritiesService);
    this.addEditForm = this.formBuilder.group({
      id: [],
      clientId: [''],
      url: [''],
      description: '',
    });
    this.title = 'common.title.add';
    if (this.permission) {
      this.title = 'common.title.edit';
      this.addEditForm.setValue(UtilsService.reduceEntityAttributeForFormControl(this.addEditForm, this.permission));
    }

    this.apiService.get<string[]>('/oauthClient/getClientIds', new HttpParams())
      .subscribe(data => {
        this.options = data.map((client: any) => new SelectModel(client, client));
      });
  }

  ngOnInit() {
  }

  get enviroment() {
    return environment;
  }

  isViewEdit(): boolean {
    if (this.addEditForm?.controls.id.value) {
      return true;
    }
    return false;
  }

  onSubmit() {
    if (this.isViewEdit()) {
      const method = this.apiService.patch('/permission/' + this.permission.id, this.addEditForm?.value);
      this.utilsService.execute(method, this.onSuccessFunc, 'common.edit.success',
        'common.confirmSave', ['common.permission.param']);
    } else {
      const method = this.apiService.post('/permission', this.addEditForm?.value);
      this.utilsService.execute(method, this.onSuccessFunc, 'common.edit.success',
        'common.confirmSave', ['common.permission.param']);
    }
  }

  onSuccessFunc = (data: any, onSuccessMessage: string | undefined): void => {
    this.utilsService.onSuccessFunc(onSuccessMessage);
    this.dialogRef.close({ value: true });
  }
}
