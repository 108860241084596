<div class="dialog_wrapper">
  <div class="dialog_header">
    <div class="dialog_title">{{title | translate}}: {{this.role?.roleName}}</div>
  </div>
  <form [formGroup]='addEditForm' class='form' fxLayout="column">
    <div class="dialog_content padding-20" fxLayout='row wrap' fxLayoutAlign='left'>
      <h4>{{'role.menu.mobile'|translate}}:</h4>
      <ns-smart-table name='menuMOBILE' formControlName='menuMOBILE'
                      [isSticky]='true'
                      [moduleName]='moduleName'
                      [columns]='menuColumns'
                      [minRow]='1'
                      fxFlex='100%'>
      </ns-smart-table>
      <hr>
      <h4>{{'role.menu.web'|translate}}:</h4>
      <ns-smart-table name='menuWEB' formControlName='menuWEB'
                      [isSticky]='true'
                      [moduleName]='moduleName'
                      [columns]='menuColumns'
                      [minRow]='1'
                      fxFlex='100%'>
      </ns-smart-table>
      <hr>
      <h4>{{'role.permission'|translate}}:</h4>
      <ns-smart-table name='permissionList' formControlName='permissionList'
                      [isSticky]='true'
                      [moduleName]='moduleName'
                      [columns]='permissionColumns'
                      [minRow]='1'
                      fxFlex='100%'>
      </ns-smart-table>
    </div>
  </form>
  <div class="dialog_footer center">
    <div>
      <button mat-button type="submit" class="primary" (click)="onSubmit()">{{'btnAddEdit'|translate}}</button>
    </div>
  </div>
</div>

