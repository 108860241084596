<div class='dialog_wrapper'>
  <div class='dialog_header'>
    <div class='dialog_title'>{{this.title | translate}}</div>
  </div>
  <div class='dialog_content padding-20'>
    <form [formGroup]='addEditForm'>
      <div class='fieldSetContent' fxLayout='row wrap' class='row-wrap-padding'>
        <ns-multi-select-autocomplete formControlName='clientId'
                                      [isLabelOutside]='enviroment.IS_LABEL_OUTSIDE'
                                      [placeholder]="moduleName+'.table.header.clientId'"
                                      [multiple]='false'
                                      [errorMessages]='clientErrorMessage'
                                      (selectionChange)='changeClientGroup($event)'
                                      [pattern]='clientIdPattern'
                                      [required]='true'
                                      [options]='options'
                                      fxLayout='column' fxFlex='100%'
        ></ns-multi-select-autocomplete>
        <ns-input [placeholder]="moduleName+'.table.header.code'" name='code' formControlName='code'
                  fxLayout='column' fxFlex='100%'
                  [pattern]='codePattern'
                  [errorMessages]='codeErrorMessage'
                  [isLabelOutside]='enviroment.IS_LABEL_OUTSIDE'
                  [required]='true'>
        </ns-input>
        <ns-input [placeholder]="moduleName+'.table.header.url'" name='code' formControlName='url'
                  fxLayout='column' fxFlex='100%'
                  [isLabelOutside]='enviroment.IS_LABEL_OUTSIDE'>
        </ns-input>
        <ns-multi-select-autocomplete formControlName='appType'
                                      [isLabelOutside]='enviroment.IS_LABEL_OUTSIDE'
                                      [placeholder]="moduleName+'.table.header.appType'"
                                      [multiple]='false'
                                      [required]='true'
                                      [options]='appTypeOptions'
                                      fxLayout='column' fxFlex='100%'
        ></ns-multi-select-autocomplete>

        <ns-multi-select-autocomplete formControlName='parentMenu'
                                      [isLabelOutside]='enviroment.IS_LABEL_OUTSIDE'
                                      [placeholder]="moduleName+'.table.header.parent.menu'"
                                      [multiple]='false'
                                      [required]='true'
                                      [options]='menuOptions'
                                      fxLayout='column'
                                      fxFlex='100%'
        ></ns-multi-select-autocomplete>
      </div>
    </form>
  </div>
  <div class='dialog_footer center'>
    <div>
      <button mat-button type='submit' class='primary' (click)='onSubmit()'
              [disabled]='!!addEditForm?.invalid'>{{'btnAddEdit'|translate}}</button>
    </div>
  </div>
</div>




