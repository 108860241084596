import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {HttpParams} from '@angular/common/http';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  ApiService,
  AuthoritiesService,
  BaseAddEditLayout,
  ColumnFields,
  ColumnTypes,
  Menu,
  Permission,
  RoleModel,
  UtilsService,
} from '@next-solutions/next-solutions-base';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';
import {Utils} from '../../../utils/utils';
import {MenuExt} from "../../../_models/MenuExt";

@Component({
  selector: 'app-add-edit-role-permission',
  templateUrl: './add-edit-role-permission.component.html',
  styleUrls: ['./add-edit-role-permission.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddEditRolePermissionComponent extends BaseAddEditLayout implements OnInit {

  MOBILE = 'MOBILE';
  WEB = 'WEB';
  title = 'common.title.edit.permission';
  menuList: Menu[] = [];
  menuIds: (number | null | undefined)[] = [];
  permissionIds: (number | null)[] = [];

  role: RoleModel | undefined;

  menuLv1: Menu[] = [];
  menuLv2: Menu[] = [];
  menuColumns: ColumnFields[] = [];
  permissionColumns: ColumnFields[] = [];

  moduleName = 'menu';

  constructor(protected activatedRoute: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected location: Location,
              protected translateService: TranslateService,
              protected apiService: ApiService,
              protected utilsService: UtilsService,
              protected authoritiesService: AuthoritiesService,
              private dialogRef: MatDialogRef<AddEditRolePermissionComponent>,
              @Inject(MAT_DIALOG_DATA) public roleParam: RoleModel) {
    super(activatedRoute, location, translateService, utilsService, authoritiesService);
    this.addEditForm = this.formBuilder.group({
      menuMOBILE: [[]],
      menuWEB: [[]],
      permissionList: [[]]
    });
    this.initMenuTable();
    this.initPermissionTable();
  }

  ngOnInit() {
    super.ngOnInit();
    this.apiService.get<RoleModel>('/role/' + this.roleParam.id, new HttpParams(), environment.BASE_AUTHORIZATION_URL)
      .subscribe(role => {
        this.role = role;
        this.menuIds = this.role.menus.map((m: Menu) => m.id);
        this.permissionIds = this.role.permissions.map((p: Permission) => p.id);
        const params = new HttpParams()
          .set('client-id', this.role.clientId ? this.role.clientId : '');
        Promise.all([
          this.apiService.get<Menu[]>('/menu/client-id', params, environment.BASE_AUTHORIZATION_URL).toPromise(),
          this.apiService.get<Permission[]>('/permission/client-id', params, environment.BASE_AUTHORIZATION_URL).toPromise(),
        ]).then(([menuList, permissionList]) => {
          if (menuList) {
            this.addEditForm.get('menuMOBILE')?.setValue(this.prepareMenu(menuList, 'MOBILE'));
            this.addEditForm.get('menuWEB')?.setValue(this.prepareMenu(menuList, 'WEB'));
          }
          if (permissionList) {
            permissionList.forEach((e: Permission) => {
              e.checked = this.isChecked(e.id, 'permission');
            });
            this.addEditForm.get('permissionList')?.setValue(permissionList);
          }
        });
      });
  }

  onSubmit() {
    const menusMobile: Menu[] = this.addEditForm.get('menuMOBILE')?.value.filter((e: any) => e.checked === true);
    const menusWeb: Menu[] = this.addEditForm.get('menuWEB')?.value.filter((e: any) => e.checked === true);
    const permissions: Permission[] = this.addEditForm.get('permissionList')?.value.filter((e: any) => e.checked === true);

    const obj = {
      clientId: this.role?.clientId,
      roleName: this.role?.roleName,
      description: this.role?.description,
      menus: [...menusMobile, ...menusWeb],
      permissions: permissions
    };

    const method = this.apiService.patch('/role/' + this.role?.id, obj, {}, environment.BASE_AUTHORIZATION_URL);
    this.utilsService.execute(method, this.onSuccessFunc, 'common.edit.success',
      'common.confirmSave', ['common.role.param']);
  }

  onSuccessFunc = (data: any, onSuccessMessage: string | undefined): void => {
    this.utilsService.onSuccessFunc(onSuccessMessage);
    this.dialogRef.close({value: true});
  }

  isChecked(id: any, type: string): boolean {
    if ((type === 'menu' && this.menuIds.includes(id))
      || (type === 'permission' && this.permissionIds.includes(id))) {
      return true;
    }
    return false;
  }

  prepareMenu(menuList: Menu[], appType: string): MenuExt[] {
    const parentMenus: MenuExt[] = menuList.filter(m => m.parentMenu === null && m.appType === appType);
    const menus: MenuExt[] = [];
    parentMenus.forEach(m => {
      m.stt = (parentMenus.indexOf(m) + 1) + '';
      m.isChildRow = false;
      m.parentRowClassIndex = 0;
      menus.push(m);
      const childMenus: MenuExt[] = menuList.filter(cm => cm.parentMenu != null && cm.parentMenu.id == m.id);
      childMenus.forEach(cm => {
        cm.stt = m?.stt + '.' + (childMenus.indexOf(cm) + 1);
        cm.isChildRow = true;
        cm.parentRowClassIndex = 1;
      });
      menus.push(...childMenus);
    });
    menus.forEach(m => m.checked = this.isChecked(m.id, 'menu'));
    return menus;
  }

  initMenuTable() {
    this.menuColumns.push(...[
      {
        columnDef: 'stt', header: 'stt',
        title: (e: MenuExt) => `${e.stt}`,
        cell: (e: MenuExt) => `${e.stt}`,
        className: 'mat-column-stt',
        isShowHeader: true, display: (e: Menu) => true,
      },
      {
        columnDef: 'code', header: 'code',
        title: (e: MenuExt) => `${e.code ? e.code : ''}`,
        cell: (e: MenuExt) => `${e.code ? e.code : ''}`,
        isShowHeader: true, display: (e: Menu) => true,
        columnType: (e: Menu) => ColumnTypes.VIEW,
        className: 'mat-column-code',
      },
      {
        columnDef: 'description', header: 'description',
        title: (e: MenuExt) => `${e.code}`,
        cell: (e: MenuExt) => `${e.code}`,
        isShowHeader: true, display: (e: Menu) => true,
        columnType: (e: Menu) => ColumnTypes.VIEW,
        className: 'mat-column-description',
      },
      {
        columnDef: 'checked', header: 'checked',
        title: (e: MenuExt) => ``,
        cell: (e: MenuExt) => ``,
        className: 'mat-column-checked',
        isShowHeader: true, display: (e: Menu) => true,
        columnType: (e: MenuExt) => ColumnTypes.CHECKBOX,
        isNotShowHeaderCheckbox: false,
        onCellValueChange: (result: MenuExt) => {
          const menus: MenuExt[] = this.addEditForm.get(`menu${result.appType}`)?.value;
          if (result.isChildRow) {
            const childs = menus.filter(m => m.parentMenu !== null && m.parentMenu.id === result.parentMenu?.id);
            const parent = menus.find(m => m.id === result.parentMenu?.id);
            if (!!parent) {
              parent.checked = childs.filter(m => !!m.checked).length === childs.length;
            }
          }
        },
      },
    ]);
  }

  initPermissionTable() {
    this.permissionColumns.push(...[
      {
        columnDef: 'stt', header: 'stt',
        title: (e: Permission) => `${Utils.getPosition(e, this.addEditForm.get('permissionList')?.value)}`,
        cell: (e: Permission) => `${Utils.getPosition(e, this.addEditForm.get('permissionList')?.value)}`,
        className: 'mat-column-stt',
        isShowHeader: true, display: (e: Permission) => true,
      },
      {
        columnDef: 'code', header: 'code',
        title: (e: Permission) => `${e.url ? e.url : ''}`,
        cell: (e: Permission) => `${e.url ? e.url : ''}`,
        isShowHeader: true, display: (e: Permission) => true,
        columnType: (e: Permission) => ColumnTypes.VIEW,
        className: 'mat-column-code',
      },
      {
        columnDef: 'description', header: 'description',
        title: (e: Permission) => `${e.description}`,
        cell: (e: Permission) => `${e.description}`,
        isShowHeader: true, display: (e: Permission) => true,
        columnType: (e: Permission) => ColumnTypes.VIEW,
        className: 'mat-column-description',
      },
      {
        columnDef: 'checked', header: 'checked',
        title: (e: Permission) => ``,
        cell: (e: Permission) => ``,
        className: 'mat-column-checked',
        isShowHeader: true, display: (e: Permission) => true,
        columnType: (e: Permission) => ColumnTypes.CHECKBOX,
        isNotShowHeaderCheckbox: false,
      },
    ]);
  }
}
