import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthoritiesResolverService } from '@next-solutions/next-solutions-base';
import { ListRoleComponent } from '../components/role/list-role/list-role.component';
import { ListUserComponent } from '../components/user/list-user/list-user.component';
import { ListClientComponent } from '../components/client/list-client/list-client.component';
import { ListMenuComponent } from '../components/menu/list-menu/list-menu.component';
import { ListPermissionComponent } from '../components/permission/list-permission/list-permission.component';
import { ListAutoPermissionComponent } from '../components/auto-permission/list-auto-permission/list-auto-permission.component';

const routes: Routes = [
  {
    path: 'role',
    data: {breadcrumb: 'menu.sso.role.'},
    component: ListRoleComponent,
    resolve: {me: AuthoritiesResolverService}
  },
  {
    path: 'user',
    data: {breadcrumb: 'menu.sso.user.'},
    component: ListUserComponent,
    resolve: {me: AuthoritiesResolverService}
  },
  {
    path: 'client',
    data: {breadcrumb: 'menu.sso.client.'},
    component: ListClientComponent,
    resolve: {me: AuthoritiesResolverService}
  },
  {
    path: 'menu',
    data: {breadcrumb: 'menu.sso.menu.'},
    component: ListMenuComponent,
    resolve: {me: AuthoritiesResolverService}
  },
  {
    path: 'permission',
    data: {breadcrumb: 'menu.sso.permission.'},
    component: ListPermissionComponent,
    resolve: {me: AuthoritiesResolverService}
  },
  {
    path: 'auto-permission',
    data: {breadcrumb: 'menu.sso.auto-permission.'},
    component: ListAutoPermissionComponent,
    resolve: {me: AuthoritiesResolverService}
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SsoRoutingModule {
}
