<div class='dialog_wrapper'>
  <div class='dialog_header'>
    <div class='dialog_title'>{{title | translate}}</div>
  </div>
  <div class='dialog_content padding-20'>
    <form [formGroup]='addEditForm'>
      <div class='fieldSetContent' fxLayout='row wrap' class='row-wrap-padding'>
        <ns-input [placeholder]="moduleName+'.table.header.clientId'" name='clientId' formControlName='clientId'
                  [required]='true'
                  [isLabelOutside]='enviroment.IS_LABEL_OUTSIDE'
                  [pattern]='clientIdPattern'
                  [errorMessages]='clientErrorMessage'
                  fxLayout='column' fxFlex='50%' [disabled]='isDetail() || isViewEdit()'
                  fxFlex.xs='100%' fxFlex.sm='50%'>
        </ns-input>

        <ns-input [placeholder]="moduleName+'.table.header.resourceIds'" name='resourceIds'
                  formControlName='resourceIds'
                  [isLabelOutside]='enviroment.IS_LABEL_OUTSIDE'
                  fxLayout='column' fxFlex='50%' [disabled]='isDetail()'
                  fxFlex.xs='100%' fxFlex.sm='50%'>
        </ns-input>

        <ns-input [placeholder]="moduleName+'.table.header.clientSecret'" name='clientSecret'
                  formControlName='clientSecret'
                  [isLabelOutside]='enviroment.IS_LABEL_OUTSIDE'
                  fxLayout='column' fxFlex='50%' [disabled]='isDetail() || isViewEdit()'
                  fxFlex.xs='100%' fxFlex.sm='50%'>
        </ns-input>


        <div fxLayout='row wrap' fxFlex='50%' fxFlex.xs='100%' fxFlex.sm='50%'>
          <mat-label>{{moduleName + '.table.header.scope' | translate}}:</mat-label>
          <mat-checkbox [id]='this.READ_SCOPE' [value]='this.READ_SCOPE' (change)='createScope($event)'
                        [disabled]='isDetail()'
                        [checked]='(isDetail() || isViewEdit()) && isCheckedScope(this.READ_SCOPE) '>{{this.READ_SCOPE.toUpperCase()}}
          </mat-checkbox>
          <mat-checkbox [id]='this.WRITE_SCOPE' [value]='this.WRITE_SCOPE' (change)='createScope($event)'
                        [disabled]='isDetail()'
                        [checked]='(isDetail() || isViewEdit()) && isCheckedScope(this.WRITE_SCOPE) '>{{this.WRITE_SCOPE.toUpperCase()}}
          </mat-checkbox>
        </div>
        <div fxLayout='column' fxFlex='100%'>
          <div fxLayout='row wrap' fxFlex='100%'>
            <mat-label>{{moduleName + '.table.header.authorizedGrantTypes'| translate}}:</mat-label>
            <mat-checkbox [id]='this.PASSWORD_GRANT_TYPE' [value]='this.PASSWORD_GRANT_TYPE'
                          (change)='createGrant_type($event)'
                          [disabled]='isDetail()'
                          [checked]='(isDetail() || isViewEdit()) && isCheckedGrant_type(this.PASSWORD_GRANT_TYPE) '>{{this.PASSWORD_GRANT_TYPE}}
            </mat-checkbox>
            <mat-checkbox [id]='this.REFRESH_TOKEN' [value]='this.REFRESH_TOKEN' (change)='createGrant_type($event)'
                          [disabled]='isDetail()'
                          [checked]='(isDetail() || isViewEdit()) && isCheckedGrant_type(this.REFRESH_TOKEN) '>{{this.REFRESH_TOKEN}}
            </mat-checkbox>
            <br>
            <mat-checkbox [id]='this.CLIENT_CREDENTIALS' [value]='this.CLIENT_CREDENTIALS'
                          (change)='createGrant_type($event)'
                          [disabled]='isDetail()'
                          [checked]='(isDetail() || isViewEdit()) && isCheckedGrant_type(this.CLIENT_CREDENTIALS) '>{{this.CLIENT_CREDENTIALS}}
            </mat-checkbox>
            <mat-checkbox [id]='this.AUTHORIZATION_CODE' [value]='this.AUTHORIZATION_CODE'
                          (change)='createGrant_type($event)'
                          [disabled]='isDetail()'
                          [checked]='(isDetail() || isViewEdit()) && isCheckedGrant_type(this.AUTHORIZATION_CODE) '>{{this.AUTHORIZATION_CODE}}
            </mat-checkbox>
          </div>
        </div>
        <ns-input [placeholder]="moduleName+'.table.header.webServerRedirectUri'" name='webServerRedirectUri'
                  formControlName='webServerRedirectUri'
                  [isLabelOutside]='enviroment.IS_LABEL_OUTSIDE'
                  fxLayout='column' fxFlex='50%' [disabled]='isDetail()'
                  fxFlex.xs='100%' fxFlex.sm='50%'>
        </ns-input>

        <div fxLayout='row wrap' fxFlex='50%' fxFlex.xs='100%' fxFlex.sm='50%'>
          <mat-label>{{moduleName + '.table.header.authorities'| translate}}:</mat-label>
          <mat-checkbox [id]='this.ROLE_CLIENT' [value]='this.ROLE_CLIENT' (change)='createAuthorities($event)'
                        [disabled]='isDetail()'
                        [checked]='(isDetail() || isViewEdit()) && isCheckedAuthorities(this.ROLE_CLIENT) '>{{this.ROLE_CLIENT}}
          </mat-checkbox>
          <mat-checkbox [id]='this.ROLE_TRUSTED_CLIENT' [value]='this.ROLE_TRUSTED_CLIENT'
                        (change)='createAuthorities($event)'
                        [disabled]='isDetail()'
                        [checked]='(isDetail() || isViewEdit()) && isCheckedAuthorities(this.ROLE_TRUSTED_CLIENT) '>{{this.ROLE_TRUSTED_CLIENT}}
          </mat-checkbox>
        </div>

        <ns-input [placeholder]="moduleName+'.table.header.accessTokenValidity'" name='accessTokenValidity'
                  formControlName='accessTokenValidity'
                  [pattern]='integerRegex'
                  [errorMessages]='integerRegexErrorMessage'
                  [isLabelOutside]='enviroment.IS_LABEL_OUTSIDE'
                  fxLayout='column' fxFlex='50%' [disabled]='isDetail()'
                  fxFlex.xs='100%' fxFlex.sm='50%'>
        </ns-input>

        <ns-input [placeholder]="moduleName+'.table.header.refreshTokenValidity'" name='refreshTokenValidity'
                  formControlName='refreshTokenValidity'
                  [pattern]='integerRegex'
                  [errorMessages]='integerRegexRefreshErrorMessage'
                  [isLabelOutside]='enviroment.IS_LABEL_OUTSIDE'
                  fxLayout='column' fxFlex='50%' [disabled]='isDetail()'
                  fxFlex.xs='100%' fxFlex.sm='50%'>
        </ns-input>

        <ns-input [placeholder]="moduleName+'.table.header.additionalInformation'" name='additionalInformation'
                  formControlName='additionalInformation'
                  [isLabelOutside]='enviroment.IS_LABEL_OUTSIDE'
                  [multiline]='true'
                  fxLayout='column' fxFlex='50%' [disabled]='isDetail()'
                  fxFlex.xs='100%' fxFlex.sm='50%'>
        </ns-input>

        <ns-input [placeholder]="moduleName+ '.table.header.autoapprove'" name='autoapprove'
                  formControlName='autoapprove'
                  [isLabelOutside]='enviroment.IS_LABEL_OUTSIDE'
                  fxLayout='column' fxFlex='50%' [disabled]='isDetail()'
                  fxFlex.xs='100%' fxFlex.sm='50%'>
        </ns-input>
      </div>
    </form>
  </div>
  <div class='dialog_footer center' *ngIf='!isDetail()'>
    <div>
      <button mat-button type='submit' class='primary' (click)='onSubmit()'
              [disabled]='!!addEditForm?.invalid'>{{'btnAddEdit'|translate}}</button>
    </div>
  </div>
</div>


