<div class='dialog_wrapper'>
  <div class='dialog_header'>
    <div class='dialog_title'>{{title | translate}}</div>
  </div>
  <div class='dialog_content padding-20'>
    <form [formGroup]='addEditForm'>
      <div fxLayout='column' fxLayoutAlign='space-around none'>
        <ns-input formControlName='username'
                  [placeholder]="moduleName+'.table.header.username'"
                  [isLabelOutside]='enviroment.IS_LABEL_OUTSIDE'
                  [errorMessages]='userNamePatternErrorMessage'
                  [pattern]='userNamePattern'
                  [required]='true'
                  fxLayout='row' fxFlex='100%'>
        </ns-input>

        <ns-input formControlName='firstName'
                  [placeholder]="moduleName+'.table.header.firstName'"
                  [isLabelOutside]='enviroment.IS_LABEL_OUTSIDE'
                  [errorMessages]='userNamePatternErrorMessage'
                  [required]='true'
                  fxLayout='row' fxFlex='100%'>
        </ns-input>

        <ns-input formControlName='lastName'
                  [placeholder]="moduleName+'.table.header.lastName'"
                  [isLabelOutside]='enviroment.IS_LABEL_OUTSIDE'
                  [errorMessages]='userNamePatternErrorMessage'
                  [required]='true'
                  fxLayout='row' fxFlex='100%'>
        </ns-input>

        <ns-multi-select-autocomplete formControlName='enabled'
                                      [isLabelOutside]='enviroment.IS_LABEL_OUTSIDE'
                                      [options]='enableOptions'
                                      [multiple]='false'
                                      [placeholder]="moduleName+'.table.header.enable'"
                                      [disabled]='!enviroment.IS_INDEPENDENCIES_SSO' fxLayout='row' >
        </ns-multi-select-autocomplete>

        <ns-multi-select-autocomplete formControlName='systemRef'
                                      [isLabelOutside]='enviroment.IS_LABEL_OUTSIDE'
                                      [options]='systemRefOptions'
                                      (selectionChange)="systemRefChange($event)"
                                      [multiple]='false'
                                      [placeholder]="moduleName+'.table.header.systemRef'"
                                      [disabled]='!enviroment.IS_INDEPENDENCIES_SSO' fxLayout='row' >
        </ns-multi-select-autocomplete>

        <ns-multi-select-autocomplete formControlName='systemRefUserType'
                                      [isLabelOutside]='enviroment.IS_LABEL_OUTSIDE'
                                      [options]='systemRefUserTypeOptions'
                                      [multiple]='false'
                                      [placeholder]="moduleName+'.table.header.systemRefUserType'"
                                      [disabled]='!enviroment.IS_INDEPENDENCIES_SSO' fxLayout='row' >
        </ns-multi-select-autocomplete>

        <ns-input formControlName='systemRefUserId'
                  [placeholder]="moduleName+'.table.header.systemRefUserId'"
                  [isLabelOutside]='enviroment.IS_LABEL_OUTSIDE'
                  [patternFilter]="'^[0-9]'"
                  [required]='true'
                  fxLayout='row' fxFlex='100%'>
        </ns-input>
      </div>
    </form>
  </div>
  <div class='dialog_footer center'>
    <div>
      <button mat-button type='submit' class='primary' (click)='onSubmit()'
              [disabled]='!!addEditForm?.invalid'>{{'btnAddEdit'|translate}}</button>
    </div>
  </div>
</div>
