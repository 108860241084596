<div class='dialog_wrapper'>
  <div class='dialog_header'>
    <div class='dialog_title'>{{title | translate}}</div>
  </div>

  <div class='dialog_content padding-20'>
    <form [formGroup]='addEditForm'>
      <div fxLayout='column' fxLayoutAlign='space-around none'>
        <ns-multi-select-autocomplete formControlName='clientId'
                                      [formGroup]='addEditForm'
                                      [isLabelOutside]='enviroment.IS_LABEL_OUTSIDE'
                                      [placeholder]="moduleName+'.table.header.clientId'"
                                      [multiple]='false'
                                      [required]='true'
                                      [options]='clientOptions'
                                      (selectionChange)='selectClient($event)'
                                      fxLayout='column' fxFlex='100%'
        ></ns-multi-select-autocomplete>
        <ns-multi-select-autocomplete formControlName='roleId'
                                      [formGroup]='addEditForm'
                                      [isLabelOutside]='enviroment.IS_LABEL_OUTSIDE'
                                      [placeholder]="moduleName+'.table.header.roleId'"
                                      [multiple]='false'
                                      [required]='true'
                                      [options]='roleOptions'
                                      fxLayout='column' fxFlex='100%'
        ></ns-multi-select-autocomplete>
        <ns-input [placeholder]="moduleName+'.table.header.systemRefUserType'" name='systemRefUserType' formControlName='systemRefUserType'
                  fxLayout='column' fxFlex='100%'
                  [isLabelOutside]='enviroment.IS_LABEL_OUTSIDE'
                  [required]='true'>
        </ns-input>
      </div>
    </form>
  </div>
  <div class='dialog_footer center'>
    <div>
      <button mat-button type='submit' class='primary' (click)='onSubmit()'
              [disabled]='!!addEditForm?.invalid'>{{'btnAddEdit'|translate}}</button>
      <button mat-button type='submit' class='primary' (click)='back()'>
        {{'btnBack'|translate}}
      </button>
    </div>
  </div>
</div>

