import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StringValidation } from '../../../validation/StringValidation';

import {
  ApiService,
  AuthoritiesService,
  BaseAddEditLayout,
  RoleModel,
  SelectModel,
  UtilsService,
} from '@next-solutions/next-solutions-base';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HttpParams } from '@angular/common/http';
import { Location } from '@angular/common';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-add-edit-role',
  templateUrl: './add-edit-role.component.html',
  styleUrls: ['./add-edit-role.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddEditRoleComponent extends BaseAddEditLayout implements OnInit {

  clientOptions: SelectModel[] = [];
  title = '';
  moduleName = 'role';

  rolePattern = '^\\w+$';
  rolePatternErrorMessage = new Map().set('pattern', () => this.translateService.instant(this.moduleName + '.error.roleName.pattern'));

  get enviroment() {
    return environment;
  }

  constructor(protected activatedRoute: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected location: Location,
              protected translateService: TranslateService,
              protected apiService: ApiService,
              protected utilsService: UtilsService,
              protected authoritiesService: AuthoritiesService,
              public dialogRef: MatDialogRef<AddEditRoleComponent>,
              @Inject(MAT_DIALOG_DATA) public role: RoleModel) {
    super(activatedRoute, location, translateService, utilsService, authoritiesService);
    this.addEditForm = this.formBuilder.group({
      id: [],
      clientId: [''],
      roleName: [''],
      description: [''],
    });
    if (environment.IS_INDEPENDENCIES_SSO) {
      this.apiService.get<string[]>('/oauthClient/getClientIds', new HttpParams(), environment.BASE_AUTHORIZATION_URL)
        .subscribe(data => {
          this.clientOptions = data.map(d => new SelectModel(d, d));
        });
    } else {
      this.clientOptions = [new SelectModel(environment.CLIENT_ID, environment.CLIENT_ID)];
      this.addEditForm.get('clientId')?.setValue(environment.CLIENT_ID);
    }
  }

  getRolePattern() {
    return StringValidation.textNotAccentedValidation;
  }

  ngOnInit() {
    super.ngOnInit();
    this.title = 'menu.sso.role.add';
    if (this.role) {
      this.title = 'menu.sso.role.edit';
      this.addEditForm.setValue(UtilsService.reduceEntityAttributeForFormControl(this.addEditForm, this.role));
    }
  }


  hasIdValue() {
    return !!this.addEditForm?.controls.id.value;
  }

  onSubmit() {
    const apiCall = this.hasIdValue()
      ? this.apiService.patch('/role/' + this.role.id, this.addEditForm?.value, {}, environment.BASE_AUTHORIZATION_URL)
      : this.apiService.post('/role', this.addEditForm?.value, {}, environment.BASE_AUTHORIZATION_URL);
    const action = this.hasIdValue() ? 'edit' : 'add';
    this.utilsService.execute(apiCall, this.onSuccessFunc,
      'common.' + action + '.success',
      'common.confirm.' + action, ['role.']);
  }

  onSuccessFunc = (data: any, onSuccessMessage: string | undefined): void => {
    this.utilsService.onSuccessFunc(onSuccessMessage);
    this.dialogRef.close({ value: true });
  };
}
