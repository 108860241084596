import {HttpParams} from '@angular/common/http';
import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  ApiService,
  AuthoritiesService,
  BaseAddEditLayout,
  Page,
  RoleModel,
  SelectModel,
  UtilsService,
} from '@next-solutions/next-solutions-base';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-add-edit-auto-permission',
  templateUrl: './add-edit-auto-permission.component.html',
  styleUrls: ['./add-edit-auto-permission.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddEditAutoPermissionComponent extends BaseAddEditLayout implements OnInit {


  clientOptions: SelectModel[] = [];
  roleOptions: SelectModel[] = [];
  options: RoleModel[] = [];
  moduleName = 'auto-permission';
  title = '';

  constructor(protected activatedRoute: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected location: Location,
              protected translateService: TranslateService,
              protected apiService: ApiService,
              protected utilsService: UtilsService,
              protected authoritiesService: AuthoritiesService,
              public dialogRef: MatDialogRef<AddEditAutoPermissionComponent>,
              @Inject(MAT_DIALOG_DATA) public configApplyUserRole: any) {
    super(activatedRoute, location, translateService, utilsService, authoritiesService);
    this.addEditForm = this.formBuilder.group({
      clientId: [''],
      roleId: [''],
      systemRefUserType: '',
    });
    this.title = 'common.title.add';
    if (this.configApplyUserRole) {
      this.title = 'common.title.edit';
      this.addEditForm.setValue(UtilsService.reduceEntityAttributeForFormControl(this.addEditForm, this.configApplyUserRole.configApplyUserRole));
      this.addEditForm.updateValueAndValidity();
    }
    const params = new HttpParams()
      .set('pageNumber', '1')
      .set('pageSize', environment.INTEGER_MAX_VALUE);
    Promise.all([
      this.apiService.get<Page>( '/role/find', params).toPromise(),
      this.apiService.get<string[]>('/oauthClient/getClientIds', params).toPromise()
    ]).then(([pageRole, clientArray]) => {
      if (clientArray) {
        this.clientOptions = clientArray.map((client: any) => new SelectModel(client, client));
      }
      if (pageRole && pageRole.content?.length >= 0) {
        this.options = pageRole.content;
        this.selectClient(this.configApplyUserRole.configApplyUserRole.clientId);
        this.addEditForm.patchValue({
          roleId: this.configApplyUserRole.configApplyUserRole.roleId,
        });
      }
    });
  }

  async ngOnInit() {
    super.ngOnInit();
  }

  selectClient(event: any) {
    this.addEditForm.get('roleId')?.setValue('');
    this.roleOptions = [];
    this.roleOptions = this.options
      .filter(a => a.clientId === event)
      ?.map(a => new SelectModel(a.id, a.roleName || ''));
  }

  get enviroment() {
    return environment;
  }

  onSubmit() {
    const apiCall = this.configApplyUserRole.configApplyUserRole ?
      this.apiService.patch('/configApplyUserRole/' + this.configApplyUserRole.configApplyUserRole.id, this.addEditForm?.value) :
      this.apiService.post('/configApplyUserRole/', this.addEditForm?.value);
    const action = this.configApplyUserRole.configApplyUserRole ? 'edit' : 'add';
    this.utilsService.execute(apiCall, this.onSuccessFunc,
      'common.' + action + '.success',
      'common.confirmSave', ['common.permission.param']);
  }

  onSuccessFunc = (data: any, onSuccessMessage: string | undefined): void => {
    this.utilsService.onSuccessFunc(onSuccessMessage);
    this.dialogRef.close({ value: true });
  }
}
