<div class='dialog_wrapper' fxLayout="column">
  <div class='dialog_header'>
    <div class='dialog_title'>{{(title + '') | translate}}: {{userData && userData.username}}</div>
  </div>
  <form [formGroup]='addEditForm' class='form' fxLayout="column">
    <div class='dialog_content padding-20' fxLayout='row wrap' fxLayoutAlign='left'>
      <ns-smart-table name='roleListForm' formControlName='roleListForm'
                      [isSticky]='true'
                      [moduleName]='moduleName'
                      [columns]='roleListColumns'
                      [minRow]='1'
                      fxFlex='100%'>
      </ns-smart-table>
    </div>
  </form>
  <div class='dialog_footer center'>
    <div>
      <button mat-button type='submit' class='primary' (click)='onSubmit()'>{{'btnAddEdit'|translate}}</button>
    </div>
  </div>
</div>
