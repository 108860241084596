import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {SharedModule} from './shared.module';
import {HttpClient} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {MultiTranslateHttpLoader, NextSolutionsModules} from '@next-solutions/next-solutions-base';
import {SsoRoutingModule} from './sso.routing.module';
import {ListRoleComponent} from '../components/role/list-role/list-role.component';
import {AddEditRoleComponent} from '../components/role/add-edit-role/add-edit-role.component';
import {AddEditRolePermissionComponent} from '../components/role/add-edit-role-permission/add-edit-role-permission.component';
import {AddEditUserComponent} from '../components/user/add-edit-user/add-edit-user.component';
import {ListUserComponent} from '../components/user/list-user/list-user.component';
import {ListClientComponent} from '../components/client/list-client/list-client.component';
import {AddEditClientComponent} from '../components/client/add-edit-client/add-edit-client.component';
import {AddRoleUserComponent} from '../components/user/add-role-user/add-role-user.component';
import {AddEditMenuComponent} from '../components/menu/add-edit-menu/add-edit-menu.component';
import {ListPermissionComponent} from '../components/permission/list-permission/list-permission.component';
import {AddEditPermissionComponent} from '../components/permission/add-edit-permission/add-edit-permission.component';
import {ListMenuComponent} from '../components/menu/list-menu/list-menu.component';
import {AddEditAutoPermissionComponent} from '../components/auto-permission/add-edit-auto-permission/add-edit-auto-permission.component';
import {ListAutoPermissionComponent} from '../components/auto-permission/list-auto-permission/list-auto-permission.component';

export function MultiTranslateHttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    {prefix: './assets/i18n/', suffix: '.json'}
  ]);
}

@NgModule({
    declarations: [
        ListRoleComponent,
        AddEditRoleComponent,
        AddEditRolePermissionComponent,
        ListUserComponent,
        AddEditUserComponent,
        AddRoleUserComponent,
        ListClientComponent,
        AddEditClientComponent,
        ListMenuComponent,
        AddEditMenuComponent,
        ListPermissionComponent,
        AddEditPermissionComponent,
        ListAutoPermissionComponent,
        AddEditAutoPermissionComponent,
    ],
    imports: [
        SharedModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: MultiTranslateHttpLoaderFactory,
                deps: [HttpClient],
            },
            isolate: true,
        }),
        SsoRoutingModule,
        NextSolutionsModules,
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SsoModule {
}
