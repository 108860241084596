<ns-breadcrumb></ns-breadcrumb>
<form class="acbox" [formGroup]="searchForm" (ngSubmit)="onSubmit()">
  <div fxLayout="row wrap" class="row-wrap-padding">
    <ns-input formControlName="systemRefUserType"
              [formGroup]="searchForm"
              [placeholder]="'common.type'"
              fxLayout="column" fxFlex="40%">
    </ns-input>
    <ns-multi-select-autocomplete formControlName="clientId"
                                  [options]="clientOptions"
                                  (selectionChange)="filterRoleIdByClientId($event)"
                                  [multiple]="false"
                                  [placeholder]="'common.clientId'"
                                  fxLayout="column" fxFlex="30%" fxFlex.lt-md="100%">
    </ns-multi-select-autocomplete>
    <ns-multi-select-autocomplete formControlName="roleId"
                                  [options]="roleOptions"
                                  [multiple]="false"
                                  [placeholder]="'common.roleId'"
                                  fxLayout="column" fxFlex="30%" fxFlex.lt-md="100%">
    </ns-multi-select-autocomplete>
    <div fxLayout="column" fxFlex="100%">
      <div class="buttonArea left">
        <button mat-button type="submit" class="primary">
          <i class="fa fa-search"></i> {{'btnSearch'|translate}}
        </button>
      </div>
    </div>
  </div>
</form>
<div class="acbox">
  <ns-table [columns]="columns"
            [buttons]="buttons"
            [paging]="paging"
            (pagingChange)="pagingChange($event)"
            [moduleName]="moduleName"
            [results]="results"
            (clickAction)="onRowButtonClick($event)">
  </ns-table>
</div>
