import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ApiService,
  AuthoritiesService,
  BaseAddEditLayout,
  Menu,
  SelectModel,
  UtilsService,
} from '@next-solutions/next-solutions-base';
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-add-edit-menu',
  templateUrl: './add-edit-menu.component.html',
  styleUrls: ['./add-edit-menu.component.scss'],
})
export class AddEditMenuComponent extends BaseAddEditLayout implements OnInit {

  moduleName = 'menu';
  codePattern = '[a-zA-Z_]+([a-zA-Z_ ]+)*';
  codeErrorMessage = new Map().set('pattern', () => this.translateService.instant(this.moduleName + '.error.code.pattern'));

  readonly clientIdPattern = '[a-zA-Z]+([a-zA-Z ]+)*';
  clientErrorMessage = new Map().set('pattern', () => this.translateService.instant(this.moduleName + '.error.clientId.pattern'));

  menuOptions: SelectModel[] = [];
  options: SelectModel[] = [];
  title = '';
  parentMenu: any;
  appTypeOptions: SelectModel[] = [];
  lstMenuOption: any;

  constructor(protected activatedRoute: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected location: Location,
              protected translateService: TranslateService,
              protected apiService: ApiService,
              protected utilsService: UtilsService,
              protected authoritiesService: AuthoritiesService,
              private snackBar: MatSnackBar,
              private dialogRef: MatDialogRef<AddEditMenuComponent>,
              @Inject(MAT_DIALOG_DATA) public menu: Menu) {
    super(activatedRoute, location, translateService, utilsService, authoritiesService);
    this.addEditForm = this.formBuilder.group({
      id: [''],
      code: [''],
      clientId: [''],
      url: '',
      appType: [''],
      parentMenu: [''],
    });

    this.appTypeOptions.push(new SelectModel('WEB', 'WEB'));
    this.appTypeOptions.push(new SelectModel('MOBILE', 'MOBILE'));
    this.title = 'menu.add';
    if (this.menu) {
      this.title = 'menu.edit';
      this.addEditForm.setValue(UtilsService.reduceEntityAttributeForFormControl(this.addEditForm, this.menu));
      this.addEditForm.updateValueAndValidity();
    }
    this.apiService.get('/menu/getAll', new HttpParams())
      .subscribe((data: Menu[] | any) => {
        this.lstMenuOption = data;
        if (this.menu) {
          // tslint:disable-next-line:no-shadowed-variable
          data = this.lstMenuOption.filter((m: { clientId: any; }) => m.clientId === this.menu.clientId) as any;
          this.addEditForm.get('parentMenu')?.setValue(this.menu.parentMenu ? this.menu.parentMenu.id : -1);
        }
        this.pushMenuOption(data);
      });
    this.apiService.get<string[]>('/oauthClient/getClientIds', new HttpParams())
      .subscribe(data => {
        this.options = data.map((client: any) => new SelectModel(client, client));
      });
  }

  config: MatSnackBarConfig = {
    duration: 3000,
    horizontalPosition: 'right',
    verticalPosition: 'top',
  };

  ngOnInit() {
  }

  get enviroment() {
    return environment;
  }


  isEditAction(): boolean {
    return !!this.addEditForm?.controls.id.value;
  }

  // onResize(event: any): void {
  //   this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  // }


  onSubmit() {
    let menu = new Menu(this.addEditForm) as Menu;
    // @ts-ignore
    if (menu && menu.parentMenu.id === -1) {
      menu.parentMenu = null;
    }
    if (this.isEditAction()) {
      const method = this.apiService.patch('/menu/' + this.menu.id, menu);
      this.utilsService.execute(method, this.onSuccessFunc, 'common.edit.success',
        'common.confirmSave', ['common.menu.param']);
    } else {
      const method = this.apiService.post('/menu', menu);
      this.utilsService.execute(method, this.onSuccessFunc, 'common.add.success',
        'common.confirmSave', ['common.menu.param']);
    }
  }

  onSuccessFunc = (data: any, onSuccessMessage: string | undefined): void => {
    this.utilsService.onSuccessFunc(onSuccessMessage);
    this.dialogRef.close({ value: true });
  }

  changeClientGroup($event: any) {
    if ($event) {
      if (this.lstMenuOption) {
        const menu = this.lstMenuOption.filter((m: { clientId: any; }) => m.clientId === $event) as any;
        this.pushMenuOption(menu);
      }
      if ((this.menu && this.menu.clientId !== $event) || (this.menu && this.menu.parentMenu == null) || !this.menu) {
        this.addEditForm.get('parentMenu')?.setValue(-1);
      } else {
        this.addEditForm.get('parentMenu')?.setValue(this.menu.parentMenu?.id);
      }
    }
  }

  pushMenuOption(data: any) {
    this.menuOptions = [];
    this.menuOptions.splice(0, 0, new SelectModel(-1, 'No parent'));
    this.menuOptions.push(...data.map((menu: any) => new SelectModel(menu.id, menu.code)));
  }
}
