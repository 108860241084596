<div class='dialog_wrapper'>
  <div class='dialog_header'>
    <div class='dialog_title'>{{this.title | translate}}</div>
  </div>
  <div class='dialog_content padding-20'>
    <form [formGroup]='addEditForm'>
      <div fxLayout='column' fxLayoutAlign='space-around none' class='fieldSetContent'>
        <!--            <mat-form-field>-->
        <!--              <mat-select matInput placeholder="{{'role.table.header.clientId'|translate}}"-->
        <!--                          formControlName="clientId" required>-->
        <!--                <mat-option *ngFor="let id of options" [value]="id">{{id}}</mat-option>-->
        <!--              </mat-select>-->
        <!--              <mat-autocomplete #auto="matAutocomplete"-->
        <!--                                (optionSelected)="getOptionValue($event)">-->
        <!--                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">-->
        <!--                  {{option}}-->
        <!--                </mat-option>-->
        <!--              </mat-autocomplete>-->
        <!--              <mat-error *ngIf="addEditForm?.controls.clientId.hasError('required')">-->
        <!--                {{'role.error.clientId.empty'|translate}}-->
        <!--              </mat-error>-->
        <!--              <mat-error *ngIf="addEditForm?.controls.clientId.hasError('pattern')">-->
        <!--                {{'role.error.clientId.pattern'|translate}}-->
        <!--              </mat-error>-->
        <!--            </mat-form-field>-->
        <ns-multi-select-autocomplete formControlName='clientId'
                                      [isLabelOutside]='enviroment.IS_LABEL_OUTSIDE'
                                      [options]='clientOptions'
                                      [multiple]='false'
                                      [placeholder]="moduleName+'.table.header.clientId'"
                                      [disabled]='!enviroment.IS_INDEPENDENCIES_SSO' fxLayout='row' >
        </ns-multi-select-autocomplete>
        <ns-input formControlName='roleName'
                  [placeholder]="moduleName+'.table.header.roleName'"
                  [isLabelOutside]='enviroment.IS_LABEL_OUTSIDE'
                  [pattern]='rolePattern'
                  [errorMessages]='rolePatternErrorMessage'
                  [required]='true'
                  fxLayout='row' fxFlex='100%'>
        </ns-input>
        <ns-input formControlName='description'
                  [placeholder]="moduleName+'.table.header.description'"
                  multiline='true'
                  [isLabelOutside]='enviroment.IS_LABEL_OUTSIDE'
                  [required]='true'
                  fxLayout='row' fxFlex='100%'>
        </ns-input>
      </div>
    </form>
  </div>
  <div class='dialog_footer center'>
    <div>
      <button mat-button type='submit' class='primary' (click)='onSubmit()'
              [disabled]='!!addEditForm?.invalid'>{{'btnAddEdit'|translate}}</button>
    </div>
  </div>
</div>

