import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserModel} from '../../../_models/user.model';
import {
  AlignEnum,
  ApiService,
  AuthoritiesService,
  BaseAddEditLayout,
  ColumnFields,
  ColumnTypes,
  RoleModel,
  UtilsService
} from '@next-solutions/next-solutions-base';
import {HttpParams} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {Utils} from '../../../utils/utils';

@Component({
  selector: 'app-add-role-user',
  templateUrl: './add-role-user.component.html',
  styleUrls: ['./add-role-user.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddRoleUserComponent extends BaseAddEditLayout implements OnInit {

  title = 'common.title.edit.role';
  user: any;
  roleList: any;
  roleIds: number[] = [];

  roleListColumns: ColumnFields[] = [];

  isView = false;
  moduleName = 'role';
  role: RoleModel | null = null;

  constructor(protected activatedRoute: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected location: Location,
              protected translateService: TranslateService,
              protected apiService: ApiService,
              protected utilsService: UtilsService,
              protected authoritiesService: AuthoritiesService,
              private dialogRef: MatDialogRef<AddRoleUserComponent>,
              @Inject(MAT_DIALOG_DATA) public userData: UserModel) {
    super(activatedRoute, location, translateService, utilsService, authoritiesService);
    this.addEditForm = this.formBuilder.group({
      roleListForm: [[]],
    });
    this.initRoleListTable();
  }

  async ngOnInit() {
    Promise.all([
      this.apiService.get<RoleModel[]>('/role/getAll', new HttpParams()).toPromise(),
      this.apiService.get<UserModel>('/user/' + this.userData.id, new HttpParams()).toPromise(),
    ]).then(([roleList, userData]) => {
      if (userData) {
        this.user = userData;
        for (const role of this.user.roles) {
          this.roleIds.push(role.id);
        }
      }
      if (roleList) {
        console.log(roleList);
        this.roleList = roleList;
        const roleListChecked = roleList.map((e: RoleModel) => {
          if (this.isChecked(e.id)) {
            e.checked = true;
            return e;
          }
          return e;
        });
        this.addEditForm.get('roleListForm')?.setValue(roleListChecked);
      }
    });
  }

  onSubmit() {
    let roleArray: RoleModel[];
    roleArray = this.roleList.filter((e: any) => e.checked === true);

    const roleMethod = this.apiService.patch('/user/' + this.user.username + '/update-roles', roleArray);
    this.utilsService.execute(roleMethod, this.onSuccessFunc, 'common.edit.success',
      'common.confirmSave', ['common.user.param']);
  }

  onSuccessFunc = (data: any, onSuccessMessage: string | undefined): void => {
    this.utilsService.onSuccessFunc(onSuccessMessage);
    this.dialogRef.close({value: true});
  }

  isChecked(roleId: any) {
    if (this.roleIds.includes(roleId)) {
      return true;
    }
  }

  initRoleListTable() {
    this.roleListColumns.push(...[
      {
        columnDef: 'stt', header: 'stt',
        title: (e: RoleModel) => `${Utils.getPosition(e, this.addEditForm.get('roleListForm')?.value)}`,
        cell: (e: RoleModel) => `${Utils.getPosition(e, this.addEditForm.get('roleListForm')?.value)}`,
        className: 'mat-column-stt',
        isShowHeader: true, display: (e: RoleModel) => true,
        align: AlignEnum.CENTER
      },
      {
        columnDef: 'roleName', header: 'roleName',
        title: (e: RoleModel) => `${e.roleName ? e.roleName : ''}`,
        cell: (e: RoleModel) => `${e.roleName ? e.roleName : ''}`,
        isShowHeader: true, display: (e: RoleModel) => true,
        columnType: (e: RoleModel) => ColumnTypes.VIEW,
        className: 'mat-column-roleName',
      },
      {
        columnDef: 'description', header: 'description',
        title: (e: RoleModel) => `${e.description}`,
        cell: (e: RoleModel) => `${e.description}`,
        isShowHeader: true, display: (e: RoleModel) => true,
        columnType: (e: RoleModel) => ColumnTypes.VIEW,
        className: 'mat-column-description',
      },
      {
        columnDef: 'checked', header: 'checked',
        title: (e: RoleModel) => ``,
        cell: (e: RoleModel) => ``,
        className: 'mat-column-checked',
        isShowHeader: true, display: (e: RoleModel) => true,
        columnType: (e: RoleModel) => ColumnTypes.CHECKBOX,
        isNotShowHeaderCheckbox: false,
      },
    ]);
  }
}
