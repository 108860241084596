<div class="login_cover">
  <mat-card>
    <mat-card-title><i class="fa fa-credit-card"></i>SINGLE SIGN ON</mat-card-title>
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="submit()">
        <p>
          <mat-form-field>
            <input type="text" matInput placeholder="{{'login.username'|translate}}" formControlName="username" height="69">
          </mat-form-field>
        </p>

        <p>
          <mat-form-field>
            <input type="password" matInput placeholder="{{'login.password'|translate}}" formControlName="password">
          </mat-form-field>
        </p>

        <p *ngIf="error" class="error">
          {{ error }}
        </p>

        <div class="button">
          <button type="submit" mat-button class="primary"><i class="fa fa-sign-in"></i> {{'login.login'|translate}}</button>
        </div>

      </form>
    </mat-card-content>
  </mat-card>
</div>
